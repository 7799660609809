import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/TextField/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/app/i18n/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/contexts/CartContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/layouts/main/dialogs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/layouts/main/header.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/locales/config-lang.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/locales/localization-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/minimals-showcase/minimals-showcase/src/locales/use-locales.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/checkout/view/checkout-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/account/view/user-account-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AddsCarouselSmall"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/adds-carousel-small.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AddsCarousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/adds-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/all-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner-1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Banner1Carousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/banner1-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/catalog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/catalogV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/categoriesBoomBuy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/full-width-banners-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/head-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/main-main-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/new-four-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/product-boom-buy-details-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextEditor"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/boom-buy/text-editor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/business-account/view/business-account-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Apartments"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/apartments.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/balance-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/banner-with-background-pic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/banner-with-balance-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/categories-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/company-description.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CompanyListCityPeople"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/company-list-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/contact-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/faq-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/header-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/history-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/history-search-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfoField"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/info-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/one-big-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/order-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServiceList"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/service-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SingleService"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/single-service.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/supplier-history-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/city-people/total-info-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/filter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/footer/footer-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/about.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-happy-holders-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-happy-hr-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners-2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners-22.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/all-shop-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/appointment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/attractions-coupons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannersCarouselWithButton"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/banners-carousel-with-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannersCarousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/banners-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/big-banner-happy-gift.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlackBanner"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/black-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/brands-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonInfoBanner"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/button-info-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/buttons-banner-happy-hr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/cards-banner-with-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/cards-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/carousel-standard-horizontal-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/CategoriesSwiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Chat"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/club-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/combined-step-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/contact-form-big.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/custom-testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/customers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DividerBlock"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/deviderBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExchangeOption"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/exchange-option.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/four-column-elements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GreyBanner"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/grey-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/happy-gift-activities-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeadActiveBanner"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/head-active-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/hottest-offers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Iframe"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/iframe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IndentationBlock"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/indentation-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/info-block-with-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/listing-reports.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/logo-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/main-banner-holders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Merchant"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MerchantsCarousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchants-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchants-categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Merchants"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/merchants.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyActiveCoupons"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-active-coupon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyCoupons"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-coupons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyESim"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-e-sim.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/my-profile/my-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NetworkInfo"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/network-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Networks"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/networks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/notifications/notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/add-to-cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/confirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/filter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/market-place.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurServicesAdd"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/our-services-add.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurServices"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/our-services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/product-details-home-service.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/our-services/service-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/pastime-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentMethods"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/payment-methods.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/photo-description.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/popular-categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/pos/pos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductDescription"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/product-description.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductsCarousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/products-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/products2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/reports-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReverseBlock"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/reverseBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SalesCarousel"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/sales-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sales"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/sales.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/search-by-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/search-home-service.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Service"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/service.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Services"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/shop-banner-6.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/sic-hover-blocks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SixTableElements"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/six-table-elements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpecialCategoriesHappyHolders"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/special-categories-happy-holders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/specialist.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/specialist/specialist-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/standard-vertical-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/standart-horizontal-banner-v1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/step-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuperProductsBanner"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/super-products-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/text-and-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-column-info-with-icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-columns-card-info-with-icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/three-columns-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-divider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-subtitle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/title-and-zip-line.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/TopBanners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/video-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VimeoIframe"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/vimeo-iframe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/happy-gift/voucher-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/add-card/addCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/buyer/buyer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/check-card/checkCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/deal-finished/deal-finished.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/default-registration/defaultRegistration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/distance/distance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/happyHr/happyHr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/leadv1/leadv1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/login-form/loginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/match-me/match-me.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/newsletter/newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/pasport-number-and-code/pasportNumberAndCode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/place/place.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/present/present.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/seller/seller.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/vendors-branches/vendorsBranches.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/vendors-details/vendorsDetails.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/want-buy/wantBuy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/blocks/want-sell/want-sell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmCityPeople"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/confirm-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DocumentView"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/document-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginCityPeople"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/login-form-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MessageCityPeople"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/message-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentCityPeople"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/popups/city-people/payment-city-people.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/resident-account/view/resident-account-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CreateGroup"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/saving-group/create-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GroupInfo"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/saving-group/group-info.tsx.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/saving-group/saving-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/saving-group/view-more.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/search/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/aml-check.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/crypto-wallet-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/deposit-wallet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/deposit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/exchange-widget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/loans.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/NFT-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/stacking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-cryptocurrency.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-exchange.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-overview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-refferral-program.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet-trade.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Wallet2"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/wallet2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/wallet-crypto/wallet-overview/withdraw.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/big-banner-elements.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blogs-text-after.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/blogs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/brands-carousel-2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/coupons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner-2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner-3.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/main-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/components/xtra/vouchers3.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedCoupons"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/generate-page/selected-coupons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-create-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-details-view-2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-details-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-edit-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-list-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-shop-details-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/product/view/product-shop-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TabLoan"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/wallet/components/tab-loan.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WelcomeBack"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/wallet/components/welcome-back/welcome-back.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/minimals-showcase/minimals-showcase/src/sections/wallet/view/wallet-view.tsx");
